import { CommunityCreationFormState } from "@/authentication/create-community/CreateCommunityPage"
import { CreateCommunityPageMutation } from "@/authentication/create-community/__generated__/CreateCommunityPageMutation.graphql"
import { OnboardingValidateFormStore } from "@/core/context/OnboardingContext"
import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import FileDropzone, { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { DiscoFormControl, DiscoInput, DiscoText, DiscoTextButton } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { Avatar, Grid } from "@material-ui/core"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { observer } from "mobx-react-lite"

interface Props {
  validationForm:
    | FormStore<CommunityCreationFormState, CreateCommunityPageMutation>
    | OnboardingValidateFormStore
  newUser?: boolean
}

function OnboardingUpdateUserProfileStepForm({ validationForm, newUser = true }: Props) {
  const classes = useStyles()

  return (
    <div className={classes.introduceYourselfContainer}>
      <DiscoText variant={"heading-lg"} align={"left"}>
        {newUser ? "Create Your Profile" : "Update Your Profile"}
      </DiscoText>
      <DiscoText
        variant={"body-md"}
        color={"groovy.neutral.600"}
        marginTop={1}
        align={"left"}
      >
        {
          "Let community members know who you are by uploading a photo and writing a short bio."
        }
      </DiscoText>
      <div className={classes.dropzonesContainers}>
        <DiscoFormControl optional label={"Avatar"} className={classes.avatarInput}>
          {validationForm.state.user!.avatar ? (
            <div className={classes.avatarContainer}>
              <Avatar
                src={validationForm.state.user!.avatar}
                alt={"profile picture"}
                classes={{ root: classes.avatar }}
              />
              <DiscoTextButton onClick={() => (validationForm.state.user!.avatar! = "")}>
                {"Remove"}
              </DiscoTextButton>
            </div>
          ) : (
            <FileDropzone
              variant={"disco-blue-small"}
              dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
              title={"Upload Profile Photo"}
              onUpload={(result) => (validationForm.state.user!.avatar! = result.url)}
              message={
                <>
                  <u style={{ fontSize: "inherit", lineHeight: "inherit" }}>{"Browse"}</u>
                  {" or drop image here"}
                </>
              }
              className={classes.avatarDropzone}
              cropperProps={{
                stencilProps: { aspectRatio: ASPECT_RATIOS.SQUARE },
              }}
            />
          )}
        </DiscoFormControl>
        <div>
          <DiscoFormControl label={"Cover Image"} className={classes.coverInput} optional>
            {validationForm.state.user!.cover ? (
              <div className={classes.coverImageContainer}>
                <div className={classes.imageContainer}>
                  <DiscoImage
                    src={validationForm.state.user!.cover}
                    alt={"User cover image"}
                    style={{
                      maxHeight: "82px",
                      width: "auto",
                      maxWidth: "100%",
                    }}
                  />
                </div>
                <DiscoTextButton
                  onClick={() => {
                    validationForm.state.user!.cover = null
                  }}
                >
                  {"Remove"}
                </DiscoTextButton>
              </div>
            ) : (
              <FileDropzone
                variant={"upload-icon"}
                className={classes.dropzone}
                showSupportedFiles={false}
                dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
                onUpload={(result) => (validationForm.state.user!.cover = result.url)}
                title={""}
                message={
                  <>
                    <u style={{ fontSize: "inherit", lineHeight: "inherit" }}>
                      {"Browse"}
                    </u>
                    {" or drop your cover image here"}
                  </>
                }
                suggestedDimensions={{ width: 120, height: 40 }}
                cropperProps={{
                  stencilProps: { aspectRatio: ASPECT_RATIOS.COVER_PHOTO },
                }}
              />
            )}
          </DiscoFormControl>
        </div>
      </div>
      <Grid container classes={{ container: classes.nameContainer }}>
        <Grid item xs>
          <DiscoFormControl
            label={"First Name"}
            errorMessages={validationForm.errorsByField.firstName}
            error={Boolean(validationForm.errorsByField.firstName)}
          >
            <DiscoInput
              placeholder={"First Name"}
              onChange={(e) => (validationForm.state.user!.firstName! = e.target.value)}
              value={validationForm.state.user!.firstName!}
              name={"firstName"}
              className={classes.input}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </DiscoFormControl>
        </Grid>
        <Grid item xs>
          <DiscoFormControl
            label={"Last Name"}
            errorMessages={validationForm.errorsByField.lastName}
            error={Boolean(validationForm.errorsByField.lastName)}
          >
            <DiscoInput
              placeholder={"Last Name"}
              onChange={(e) => (validationForm.state.user!.lastName! = e.target.value)}
              value={validationForm.state.user!.lastName || ""}
              name={"lastName"}
              className={classes.input}
            />
          </DiscoFormControl>
        </Grid>
      </Grid>

      <DiscoFormControl
        fullWidth
        label={"Short Bio"}
        errorMessages={validationForm.errorsByField.bio}
      >
        <DiscoInput
          placeholder={"Bio"}
          onChange={(e) => (validationForm.state.user!.bio! = e.target.value)}
          value={validationForm.state.user!.bio || ""}
          name={"bio"}
          className={classes.input}
          fullWidth
        />
      </DiscoFormControl>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  introduceYourselfContainer: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    borderRadius: theme.measure.borderRadius.big,
  },
  dropzonesContainers: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: theme.spacing(3),
    gap: theme.spacing(2.5),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  },
  avatar: {
    height: "96px",
    width: "96px",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
    },
  },
  avatarInput: {
    display: "flex",
    flexDirection: "column",

    width: "150px",
    height: "100%",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
    },
  },
  coverInput: {
    marginBottom: theme.spacing(2),
  },
  nameContainer: {
    gap: theme.spacing(2),
  },
  avatarDropzone: {
    width: "96px",
    height: "96px",
    borderRadius: "50%",
  },
  dropzone: {
    width: "100%",
    height: "100px",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    justifyContent: "center",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #D5D9DE",
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(1, 3),
    maxHeight: "96px",
  },
  coverImageContainer: {
    display: "flex",
    width: "fit-content",
    flexDirection: "column",
    justifyContent: "center",
  },
}))

export default observer(OnboardingUpdateUserProfileStepForm)
