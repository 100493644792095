import { JoinCommunityFormStore } from "@/authentication/join-community/JoinCommunityForm"
import { AdminJoinCommunityButtonMutation } from "@/authentication/join-community/__generated__/AdminJoinCommunityButtonMutation.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import { capitalize } from "lodash"
import React, { useState } from "react"
import { graphql } from "relay-runtime"

interface AdminJoinCommunityButtonProps {
  form: JoinCommunityFormStore
}

const AdminJoinCommunityButton: React.FC<AdminJoinCommunityButtonProps> = ({ form }) => {
  const [isLoading, setIsLoading] = useState(false)

  const adminInviteForm = useFormStore<AdminJoinCommunityButtonMutation>(
    graphql`
      mutation AdminJoinCommunityButtonMutation($input: VerifyAdminInvitationInput!) {
        response: verifyAdminInvitation(input: $input) {
          node {
            id
            firstName
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      adminInviteToken: form.state.inviteToken!,
    }
  )

  const role =
    form.state.invitation?.productRole ||
    form.state.invitation?.organizationRole ||
    "Admin"

  const productName = form.state.invitation?.product?.name

  return (
    <DiscoButton
      shouldDisplaySpinner={isLoading}
      onClick={handleAdminJoin}
      type={"button"}
      testid={"JoinCommunityForm.submit-button"}
      width={"100%"}
    >
      {`Join ${productName || "Community"} as ${capitalize(role)}`}
    </DiscoButton>
  )
  async function handleAdminJoin() {
    setIsLoading(true)
    try {
      if (!form.state.inviteToken) {
        throw new Error("Missing admin invitation token")
      }

      const { didSave, response } = await adminInviteForm.submit(adminInviteForm.state)
      if (!didSave || !response?.node) {
        throw new Error(
          "Invitation has expired. Please contact a community admin for a new invitation."
        )
      }

      form.state.step = "success"
    } catch (error) {
      displayErrorToast(error)
    }
    setIsLoading(false)
  }
}

export default AdminJoinCommunityButton
