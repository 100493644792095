import {
  postWebViewMessage,
  WebViewMessage,
  WebViewMessageType,
} from "@/apps/util/hooks/useWebViewMessage"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

/**
 * Handle redirecting to a mobile path when viewing the site on mobile
 */
export function useWebViewMobileRedirect() {
  const location = useLocation()
  const isWebView = useIsWebView()

  /**
   * If the path is handled on mobile, stop the redirect and send
   * a message to the webview to redirect on mobile instead
   */
  useEffect(() => {
    if (!isWebView) return
    handleMobileRedirect(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
}

/**
 * List of paths that are handled on mobile
 *
 * Must stay in sync with `FRONTEND_TO_MOBILE_PATHS` in `mobile/routeUtils.ts`
 */
const HANDLED_MOBILE_PATHS: string[] = [
  // Authentication
  ROUTE_NAMES.AUTHENTICATION.LOGIN,
  ROUTE_NAMES.AUTHENTICATION.SOCIAL_AUTH_REDIRECT,
  ROUTE_NAMES.AUTHENTICATION.LOGIN_WITH_TOKEN,
  ROUTE_NAMES.AUTHENTICATION.SSO_AUTH,

  // Chat
  ROUTE_NAMES.COMMUNITY.CHAT.CHANNEL,
  ROUTE_NAMES.COMMUNITY.THREADS.ROOT,
  ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.ROOT,
  ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL,
  ROUTE_NAMES.PRODUCT.CHAT.LIST,
  ROUTE_NAMES.PRODUCT.CHAT.CHANNEL,
  ROUTE_NAMES.PRODUCT.CHAT.DIRECT_MESSAGE,

  // Products
  ROUTE_NAMES.COMMUNITY.EXPERIENCES.ROOT,
  ROUTE_NAMES.COMMUNITY.EXPERIENCES.COURSES.ROOT,
  ROUTE_NAMES.COMMUNITY.EXPERIENCES.COURSES.UPCOMING,
  ROUTE_NAMES.COMMUNITY.EXPERIENCES.COURSES.PAST,

  // Product
  ROUTE_NAMES.PRODUCT.ROOT,
  ROUTE_NAMES.PRODUCT.DASHBOARD,
  ROUTE_NAMES.PRODUCT.CURRICULUM.ROOT,
  ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW,
  ROUTE_NAMES.PRODUCT.CURRICULUM.MODULE,
  ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST,
]

/**
 * Find a matching path from the frontend URL and return the mobile path
 */
function getMobileRoute(currentPath: string) {
  // Check if the path is handled on mobile
  for (const handledPath of HANDLED_MOBILE_PATHS) {
    const regex = convertPathToRegex(handledPath)
    // If the path doesn't match the regex, continue
    if (!regex.test(currentPath)) continue
    return {
      path: handledPath,
      params: getParamsFromPath(currentPath, handledPath),
    }
  }

  // If no path is found, return null
  return null
}

/**
 * Convert a path to a regex that will match the path. This will replace any
 * variables (:productSlug) with a regex that will match anything. Add (?:$|/(?=$))
 * to the end of the regex to ensure that the regex will match the entire path and
 * that there will not be another segment after the path.
 *
 * ie. /p/member-space/curriculum does not match /p/:productSlug
 */
function convertPathToRegex(path: string): RegExp {
  // convert the path to a regex
  const regex = path.replace(/:[^/]+/g, "([^/]+)")
  return new RegExp(`^${regex}(?:$|/(?=$))`)
}

/**
 * Get the params from the current path that match the compared path
 * ie. current: /p/123, compare: /p/:productSlug, returns { productSlug: "123" }
 */
function getParamsFromPath(currentPath: string, comparePath: string) {
  const regex = convertPathToRegex(comparePath)
  const match = currentPath.match(regex)
  if (!match) return {}

  // Get the params from the compared path
  const params: Record<string, string> = {}
  const paramNames = comparePath.match(/:[^/]+/g) || []
  for (let i = 0; i < paramNames.length; i++) {
    const paramName = paramNames[i].replace(":", "")
    params[paramName] = match[i + 1]
  }

  return params
}

/**
 * If the path is handled on mobile, stop the redirect and send
 * a message to the webview to redirect instead
 */
function handleMobileRedirect(path: string) {
  // Check if the path is handled on mobile
  const mobileRoute = getMobileRoute(path)
  if (!mobileRoute) return

  // Create the message and send to the callback
  const message: WebViewMessage = {
    type: WebViewMessageType.mobile_redirect,
    frontendPath: mobileRoute.path,
    params: mobileRoute.params,
  }

  postWebViewMessage(message)
}
