import {
  CreateCommunityPageMutation,
  CreateCommunityPageMutation$variables,
  ValidateCommunityCreationStepInput,
} from "@/authentication/create-community/__generated__/CreateCommunityPageMutation.graphql"
import OnboardingPage from "@/authentication/onboarding-communication-apps/OnboardingPage"
import OnboardingUpdateUserProfileStepForm from "@/authentication/update-user-profile/onboarding/form/OnboardingUpdateUserProfileStepForm"
import useSteps from "@/common/hooks/useSteps"
import { BadgeKind } from "@/community/__generated__/CommunityBadgeFragment.graphql"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { switchToOrganizationDomain } from "@/core/route/util/routeUtils"
import CreateCommunityLoadingPage from "@/organization/create/CreateCommunityLoadingPage"
import AddCommunityDescriptionForm from "@/organization/create/form/AddCommunityDescriptionForm"
import CommunityTemplateQuestion from "@/organization/create/form/CommunityTemplateQuestion"
import CreateCommunityForm from "@/organization/create/form/CreateCommunityForm"
import NewOrExistingCommunityQuestion from "@/organization/create/form/NewOrExistingCommunityQuestion"
import useCreateCommunity from "@/organization/create/hooks/useCreateCommunity"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { DiscoButton, DiscoTextButton } from "@disco-ui"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { isE2ETest } from "@utils/e2e"
import { userIsNew } from "@utils/onboarding/onboardingUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"

export type CommunityCreationStep =
  | "new_or_existing"
  | "organization"
  | "organization_description"
  | "template"
  | "user"
  | "company_domain"
const SKIPPABLE_STEPS: CommunityCreationStep[] = [
  "new_or_existing",
  "organization_description",
]

export type SignUpQueryParams = {
  // Signup source
  s?: string
}
export type OrganizationAttribute = "signup_source" | "new_or_existing"

export type CommunityCreationFormState = Omit<
  ValidateCommunityCreationStepInput,
  "attributes"
> & {
  attributes: { name: OrganizationAttribute; value: string }[]
}

function CreateCommunityPage() {
  const location = useLocation()
  const { authUser: user } = useAuthUser()
  const isNewUser = userIsNew(user?.firstName, user?.lastName)

  const steps: CommunityCreationStep[] = [
    "new_or_existing",
    "organization",
    "organization_description",
    "template",
    ...ArrayUtils.spreadIf("user" as CommunityCreationStep, isNewUser),
  ]

  const {
    currentStep,
    goToStep,
    goToNextStep,
    goToPreviousStep,
    getCurrentStepIndex,
    hasNextStep,
    hasPreviousStep,
  } = useSteps<CommunityCreationStep>({
    initialStep: "new_or_existing",
    steps,
  })
  const { form: createForm, handleCreateCommunity } = useCreateCommunity()
  const classes = useStyles()

  // Capture any marketing query params if they exist
  const { s } = useQueryParams<SignUpQueryParams>()

  const state = location.state as
    | CreateCommunityPageMutation$variables["input"]
    | undefined

  const validationForm = useFormStore<
    CreateCommunityPageMutation,
    CommunityCreationFormState
  >(
    graphql`
      mutation CreateCommunityPageMutation($input: ValidateCommunityCreationStepInput!) {
        response: validateCommunityCreationStep(input: $input) {
          errors {
            field
            message
          }
        }
      }
    `,
    {
      step: "organization",
      organization: {
        badge: {
          color:
            state?.organization?.badge?.kind === "emoji"
              ? "#ffffff00"
              : state?.organization?.badge?.color || getDefaultCommunityBadge().color,
          kind: state?.organization?.badge?.kind || getDefaultCommunityBadge().kind,
          emoji: state?.organization?.badge?.emoji,
          mediaUrl: state?.organization?.badge?.mediaUrl,
          icon: state?.organization?.badge?.icon,
          assetId: state?.organization?.badge?.assetId,
        },
        coverUrl: state?.organization?.coverUrl || null,
        logo: state?.organization?.logo || null,
        name: state?.organization?.name || "",
        slug: state?.organization?.slug || "",
      },
      organizationDescription: {
        description: state?.organizationDescription?.description || "",
      },
      user: state?.user
        ? state.user
        : {
            firstName: "",
            lastName: "",
          },
      attributes: s ? [{ name: "signup_source", value: s }] : [],
      template: null,
    },
    {
      requireChangeToSubmit: !state?.organization,
    }
  )

  const isLoading = validationForm.isSubmitting

  useEffect(() => {
    if (!state?.step) return

    goToStep(state.step as CommunityCreationStep)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.step])

  const [isRedirecting, setIsRedirecting] = useState(false)
  if (createForm.isSubmitting || isRedirecting) return <CreateCommunityLoadingPage />

  const isSkippable = SKIPPABLE_STEPS.includes(currentStep)

  return (
    <div className={classes.createCommunityContainer}>
      <OnboardingPage
        metaTitle={"Create Your Community"}
        steps={[getCurrentStepIndex(currentStep) + 1, steps.length]}
        body={
          <Form
            testid={"CreateOrganizationForm"}
            customClassName={classes.form}
            classes={{ formFieldsContainer: classes.formFieldsContainer }}
          >
            <div className={classes.bodyContainer}>
              {currentStep === "new_or_existing" ? (
                <NewOrExistingCommunityQuestion validationForm={validationForm} />
              ) : currentStep === "organization" ? (
                <CreateCommunityForm validationForm={validationForm} />
              ) : currentStep === "organization_description" ? (
                <AddCommunityDescriptionForm validationForm={validationForm} />
              ) : currentStep === "template" ? (
                <CommunityTemplateQuestion validationForm={validationForm} />
              ) : currentStep === "user" ? (
                <OnboardingUpdateUserProfileStepForm
                  validationForm={validationForm}
                  newUser={isNewUser}
                />
              ) : null}
            </div>

            <div className={classes.buttonsContainer}>
              <div className={classes.buttons}>
                {hasPreviousStep() && (
                  <DiscoButton
                    onClick={goToPreviousStep}
                    disabled={isLoading}
                    color={"grey"}
                    variant={"outlined"}
                  >
                    {"Back"}
                  </DiscoButton>
                )}
              </div>
              <div className={classes.buttons}>
                {isSkippable && (
                  <DiscoTextButton
                    testid={"CreateCommunityPage.skip-button"}
                    onClick={() => handleContinueOrCreate(true)}
                    disabled={isLoading}
                    variant={"grey"}
                    textVariant={"body-md-600"}
                  >
                    {"Skip"}
                  </DiscoTextButton>
                )}
                <DiscoButton
                  onClick={() => handleContinueOrCreate()}
                  shouldDisplaySpinner={isLoading}
                  disabled={isContinueDisabled()}
                  testid={"CreateCommunityPage.continue-or-create-button"}
                >
                  {hasNextStep() ? "Continue" : "Create"}
                </DiscoButton>
              </div>
            </div>
          </Form>
        }
      />
    </div>
  )

  async function handleContinueOrCreate(skipped = false) {
    if (skipped) {
      // Clear the step's input instead of validating it if skipped
      if (currentStep === "new_or_existing") {
        const index = validationForm.state.attributes.findIndex(
          (a) => a.name === "new_or_existing"
        )
        if (index >= 0) validationForm.state.attributes.splice(index, 1)
      } else if (currentStep === "organization_description") {
        if (validationForm.state.organizationDescription?.description)
          validationForm.state.organizationDescription.description = ""
      }
    } else {
      // Validate the step before submitting the create form, so any errors can be shown
      // before displaying the splash page
      const didSave = await handleValidateStep()
      if (!didSave) return
    }
    if (hasNextStep()) {
      goToNextStep()
    } else {
      handleCreate()
    }
  }

  async function handleValidateStep() {
    const { didSave } = await validationForm.submit({
      ...validationForm.state,
      organizationDescription: {
        description:
          validationForm.state.organizationDescription?.description || undefined,
      },
      step: currentStep,
    })
    return didSave
  }

  async function handleCreate() {
    const startTime = Date.now()
    const newOrganization = await handleCreateCommunity({
      user: isNewUser ? validationForm.state.user : undefined,
      organization: validationForm.state.organization,
      organizationDescription: {
        description:
          validationForm.state.organizationDescription?.description || undefined,
      },
      attributes: validationForm.state.attributes,
      template: validationForm.state.template,
    })
    if (!newOrganization) return

    // Show the creation splash screen for a minimum of 5s before redirecting
    setIsRedirecting(true)
    const minWaitTime = isE2ETest() ? 0 : 5000
    const timeToWait = minWaitTime - (Date.now() - startTime)
    if (timeToWait > 0) await new Promise((res) => setTimeout(res, timeToWait))

    switchToOrganizationDomain(
      newOrganization,
      null,
      ROUTE_NAMES.COMMUNITY.HOME.ROOT,
      "?new=true"
    )
  }

  function getDefaultCommunityBadge() {
    const badgeColors = [
      "#4484FF",
      "#20CA8D",
      "#A78BFA",
      "#F56D6D",
      "#FA8F58",
      "#FFD762",
      "#FF78E9",
    ]

    const color = badgeColors[Math.floor(Math.random() * badgeColors.length)]

    return {
      color,
      kind: "icon" as BadgeKind,
    }
  }

  function isContinueDisabled() {
    if (isLoading) return true
    if (currentStep === "new_or_existing")
      return !validationForm.state.attributes.some((a) => a.name === "new_or_existing")
    if (currentStep === "organization_description")
      return !validationForm.state.organizationDescription?.description
    if (currentStep === "template") return !validationForm.state.template
    return false
  }
}

const useStyles = makeUseStyles((theme) => ({
  createCommunityContainer: {
    height: "100%",
    backgroundImage: theme.palette.gradient.newCommunityOnboarding,
  },
  bodyContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(2),
  },
  form: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  formFieldsContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}))

export default observer(CreateCommunityPage)
