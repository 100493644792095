import {
  BrainSearchLandingPageMutation,
  CreateBrainSearchInput,
} from "@/brain-search/internal/__generated__/BrainSearchLandingPageMutation.graphql"
import { ROLES } from "@/brain-search/internal/brainSearchUtils"
import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoSelect, DiscoText, SelectOption } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useMemo } from "react"

type Props = {
  form: FormStore<CreateBrainSearchInput, BrainSearchLandingPageMutation>
}

function BrainSearchRoleSelection({ form }: Props) {
  const classes = useStyles()
  const { role } = form.state
  const experts = useMemo(() => (role ? ROLES[role].experts : []), [role])

  return (
    <>
      {/* Role Selection */}
      <div className={classes.roleSelection}>
        <DiscoText variant={"body-sm"} color={"groovy.neutral.400"}>
          {"Answers from top experts including:"}
        </DiscoText>

        <DiscoSelect
          value={role}
          onChange={(value) => (form.state.role = value)}
          options={renderOptions()}
          placeholder={"Select Role"}
          renderOption={(option) => (
            <DiscoText variant={"body-sm"} color={"groovy.neutral.500"}>
              {option.title}
            </DiscoText>
          )}
          classes={{ root: classes.roleButton, inputRoot: classes.roleInput }}
          textFieldInputProps={{
            classes: {
              input: classes.roleInputText,
            },
            startAdornment: (
              <DiscoIcon
                icon={"user"}
                marginRight={1}
                marginLeft={0.5}
                height={16}
                width={16}
              />
            ),
          }}
        />
      </div>

      {/* Experts List */}
      {Boolean(experts.length) && (
        <div className={classes.expertsList}>
          {experts.map((expert) => {
            const name = Object.keys(expert)[0]
            const { avatar } = expert[name]

            return (
              <div key={name} className={classNames(classes.expert, classes.fadeIn)}>
                <DiscoImage src={avatar} className={classes.avatar} />
                <DiscoText variant={"body-sm"} color={"groovy.neutral.500"}>
                  {name}
                </DiscoText>
              </div>
            )
          })}
        </div>
      )}
    </>
  )

  function renderOptions(): SelectOption<string>[] {
    return Object.keys(ROLES).map((title) => ({ value: title, title }))
  }
}

const useStyles = makeUseStyles((theme) => ({
  expertsList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "clex-start",
    gap: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  expert: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),
    flex: "1 1 0px",
    textAlign: "center",
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    border: theme.palette.constants.borderSmall,
    background:
      "radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), var(--Colors-blue-600, #4D47DC)",
  },
  roleSelection: {
    display: "flex",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  roleButton: {
    padding: theme.spacing(0.5),
    minWidth: "250px",
    height: "32px",
    borderRadius: 50,
    border: theme.palette.constants.borderSmall,
  },
  roleInput: {
    backgroundColor: theme.palette.common.white,
    padding: "0 !important",
    height: "24px",
    minWidth: "175px",
    "&.Mui-focused": {
      boxShadow: "unset",
      backgroundColor: theme.palette.common.white,
    },
    "&:hover:not(.Mui-focused)": {
      backgroundColor: theme.palette.common.white,
      boxShadow: "unset",
    },
  },
  roleInputText: {
    color: theme.palette.groovy.neutral[500],
    ...theme.typography["body-sm"],
  },
  fadeIn: {
    animation: "$fadeIn 0.5s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export default observer(BrainSearchRoleSelection)
