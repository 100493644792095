import { useWebViewMessageRedirectListener } from "@/apps/util/hooks/useWebViewMessageRedirectListener"
import { useWebViewMobileRedirect } from "@/apps/util/hooks/useWebViewMobileRedirect"
import { GlobalID } from "@/relay/RelayTypes"

/**
 * WebViewMessage types must stay in sync with `mobile/WebViewContext.tsx`
 */
export enum WebViewMessageType {
  mobile_redirect = "mobile_redirect",
  frontend_redirect = "frontend_redirect",
  frontend_ready = "frontend_ready",
  frontend_goback = "frontend_goback",
  on_registration = "on_registration",
  frontend_scroll = "frontend_scroll",
  frontend_scrollend = "frontend_scrollend",
  dismiss_notifications = "dismiss_notifications",
  open_comments = "open_comments",
  refetch_products = "refetch_products",
}

export type WebViewMessage =
  | {
      type: WebViewMessageType.mobile_redirect
      frontendPath: string
      params: Record<string, string>
    }
  | {
      type: WebViewMessageType.frontend_redirect
      frontendPath?: string
      params?: Record<string, string>
    }
  | {
      type: WebViewMessageType.frontend_ready
    }
  | {
      type: WebViewMessageType.frontend_goback
    }
  | {
      type: WebViewMessageType.on_registration
      frontendPath: string
    }
  | {
      type: WebViewMessageType.frontend_scroll
      scroll: number
    }
  | {
      type: WebViewMessageType.frontend_scrollend
      scroll: number
    }
  | {
      type: WebViewMessageType.dismiss_notifications
      notificationIds: string[]
    }
  | {
      type: WebViewMessageType.open_comments
      contentUsageId: GlobalID
      contentId: GlobalID
    }
  | {
      type: WebViewMessageType.refetch_products
    }

type WebViewMessageCallbacks = {
  postWebViewMessage: (data: string) => void
}

type WebViewWindow = Window & WebViewMessageCallbacks

/**
 * Handle receiving messages from ReactNative and posting messages to ReactNative
 */
export function useWebViewMessage() {
  /**
   * Listener to receive messages posted from ReactNative
   */
  useWebViewMessageRedirectListener()

  /**
   * If the path is handled on mobile, stop the redirect and send
   * a message to the webview to redirect on mobile instead
   */
  useWebViewMobileRedirect()
}

/**
 * Post a message to the mobile app through the webview
 */
export function postWebViewMessage(message: WebViewMessage) {
  if (!(window as any).postWebViewMessage) return
  const w = window as unknown as WebViewWindow
  w.postWebViewMessage(JSON.stringify(message))
}
