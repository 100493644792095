import AuthenticationPage from "@/authentication/common/page/AuthenticationPage"
import DiscoLogo from "@/core/ui/images/disco-logo-light.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import DiscoProgressBarStepper from "@disco-ui/stepper/DiscoProgressBarStepper"
import { Theme, useMediaQuery } from "@material-ui/core"
import classnames from "classnames"

import { ClassNameMap } from "notistack"
import { ReactNode } from "react"

type Props = {
  classes?: ClassNameMap<"rightSideContent">
  body: ReactNode
  metaTitle: string
  steps?: [number, number]
  version?: "v1" | "v2"
}

export default function OnboardingPage(props: Props) {
  const { metaTitle, body, steps, version } = props
  const classes = useStyles({ version })
  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"))

  return (
    <div
      className={classnames({
        [classes.backgroundContainer]: version === "v2",
      })}
    >
      <div className={classes.onboardingContainer}>
        {!isSmallScreen && <DiscoLogo className={classes.logo} />}

        <AuthenticationPage
          classes={{ section: classes.section }}
          hideBackground
          metaTitle={metaTitle}
        >
          {steps && <DiscoProgressBarStepper steps={steps} />}
          <div className={classes.root}>
            <div className={classes.body}>{body}</div>
          </div>
        </AuthenticationPage>
      </div>
    </div>
  )
}

type StyleProps = {
  version?: "v1" | "v2"
}

const useStyles = makeUseStyles((theme) => ({
  backgroundContainer: {
    backgroundImage: theme.palette.gradient.newCommunityOnboarding,
    height: "100%",
  },
  // component classes
  onboardingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  logo: {
    color: "white",
    width: "206px",
    height: "45px",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: "auto",
  },
  body: ({ version }: StyleProps) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: 400,

    ...styleIf(version === "v2", {
      minHeight: "unset",
      flexGrow: 1,
      justifyContent: "space-between",
    }),

    [theme.breakpoints.down("md")]: {
      minHeight: 500,

      ...styleIf(version === "v2", {
        minHeight: "unset",
      }),
    },
  }),
  // PageContent classes
  section: {
    maxWidth: "936px",
    maxHeight: "80%",
    padding: theme.spacing(10),

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(5),
      maxHeight: "100%",
    },
  },
}))
