import { Invitation } from "@/authentication/join-community/JoinCommunityPage"
import { OrganizationOnboardingInfoSectionFragment$key } from "@/authentication/join-community/__generated__/OrganizationOnboardingInfoSectionFragment.graphql"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { graphql, useFragment } from "react-relay"
import CoverPhoto, { CoverPhotoSkeleton } from "../../components/cover-photo/CoverPhoto"

interface OrganizationOnboardingInfoSectionProps {
  organizationKey: OrganizationOnboardingInfoSectionFragment$key
  invitation?: Invitation | null
}

export default function OrganizationOnboardingInfoSection({
  organizationKey,
  invitation,
}: OrganizationOnboardingInfoSectionProps) {
  const classes = useStyles()
  const theme = useTheme()
  const organization = useFragment<OrganizationOnboardingInfoSectionFragment$key>(
    graphql`
      fragment OrganizationOnboardingInfoSectionFragment on Organization {
        id
        cover
        name
        registrationDescription
      }
    `,
    organizationKey
  )

  return (
    <div className={classes.container}>
      <div className={classes.stickyContent}>
        <CoverPhoto
          coverPhoto={organization.cover}
          customClassName={classes.coverPhoto}
        />
        <DiscoText
          variant={"heading-md"}
          marginBottom={1.5}
          testid={"OrganizationOnboardingInfoSection.name"}
        >
          {invitation?.product?.name || organization.name}
        </DiscoText>
        {invitation && (
          <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={2}>
            {invitation.product
              ? `📬 You've been invited to join as a ${invitation.productRole} for ${invitation.product.name} in ${organization.name}!`
              : `📬 You've been invited to join as a ${invitation.organizationRole} in ${organization.name}!`}
          </DiscoText>
        )}
        {organization.registrationDescription && (
          <DiscoEditor
            testid={"OrganizationOnboardingInfoSection.description"}
            defaultValue={organization.registrationDescription}
            backgroundColor={theme.palette.groovy.neutral[100]}
            disableGutter
            readOnly
          />
        )}
      </div>
    </div>
  )
}

export function OrganizationOnboardingInfoSectionSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <CoverPhotoSkeleton className={classes.coverPhoto} />
      <DiscoTextSkeleton variant={"heading-md"} marginBottom={1} width={"90%"} />
      <div>
        <DiscoTextSkeleton width={"100%"} marginTop={4.5} />
        <DiscoTextSkeleton width={"100%"} />
        <DiscoTextSkeleton width={"100%"} />
        <DiscoTextSkeleton width={"40%"} />
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      height: "100%",
      padding: theme.spacing(4, 6),
      backgroundColor: theme.palette.groovy.neutral[100],
      borderRadius: theme.measure.borderRadius.big,
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(6, 10),
    },
  },
  stickyContent: {
    // Sticky content on scroll
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: theme.spacing(6),
    },
  },
  coverPhoto: {
    marginBottom: theme.spacing(3),
  },
}))
